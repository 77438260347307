import { spTrackWebInteraction } from "~/utils/tracking";

export function CookieSettingLink() {
	const handleClick = () => {
		spTrackWebInteraction({
			object: "consent button",
			action: "click",
			value: "Cookies settings",
		});
	};

	return (
		<button
			className="optanon-toggle-display text-primary whitespace-nowrap px-3 text-xs hover:underline" //optanon-toggle-display required to trigger One Trust cookies settings
			onClick={handleClick}
		>
			Cookie settings
		</button>
	);
}
