import { useSpinDelay } from "spin-delay";
import { useSharedContent } from "~/hooks/localization";
import { Icon } from "./ui/icons";
import { Notification } from "./ui/notification";
import { useNavigation } from "@remix-run/react";

export function PageLoading() {
	const navigation = useNavigation();
	const { t } = useSharedContent();

	const isLoading = useSpinDelay(Boolean(navigation.state !== "idle"), {
		delay: 400,
		minDuration: 1000,
	});

	return (
		<Notification
			notiKey="page-loading"
			visible={isLoading}
			icon={<Icon name="spinner" color="primary" />}
			className="w-[380px]"
			title={t("loadingPageTitle")}
			message={t("loadingPageMessage")}
		/>
	);
}
